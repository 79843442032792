import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { keyframes } from '@emotion/core';
import { Box } from '@qga/roo-ui/components';

const fadeIn = keyframes`
  from { opacity: 0; }
  to   { opacity: 1; }
`;

const Wrapper = styled(Box)`
  overflow: hidden;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const Inner = styled.img`
  display: block;
  width: 100%;
  height: 100%;
  object-fit: ${(props) => (props.cover ? (props.sanityImage ? 'fill' : 'cover') : 'contain')};
  &[data-animate] {
    animation: ${fadeIn} 250ms;
  }
`;

const ImageInner = ({ src, srcSet, alt, cover, animate, sanityImage, ...rest }) => {
  return (
    <Wrapper cover={cover} {...rest}>
      <Inner src={src} srcSet={srcSet} alt={alt} cover={cover} sanityImage={sanityImage} data-animate={animate ? true : undefined} />
    </Wrapper>
  );
};

ImageInner.displayName = 'ImageInner';

ImageInner.propTypes = {
  src: PropTypes.string.isRequired,
  srcSet: PropTypes.string,
  alt: PropTypes.string.isRequired,
  cover: PropTypes.bool,
  sanityImage: PropTypes.bool,
  animate: PropTypes.bool,
};

ImageInner.defaultProps = {
  srcSet: undefined,
  cover: true,
  animate: false,
  sanityImage: false,
};

export default ImageInner;
