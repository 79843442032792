/* eslint-disable react/prop-types */
import React from 'react';
import styled from '@emotion/styled';
import { themeGet } from 'styled-system';
import fallbackUrl from './placeholder.svg';
import { Box } from '@qga/roo-ui/components';

type ImageFallbackProps = {
  alt: string;
  width?: string | number | string[] | number[];
  height?: string | number | string[] | number[];
};

const ImageFallbackWrapper = styled(Box)`
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${themeGet('colors.greys.porcelain')};
  background: linear-gradient(135deg, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 34%, rgb(247, 247, 247) 100%);
`;

// ".gm-style" selector has been added to overwrite a google maps selector ".gm-style img"
const ImageFallbackInner = styled.img`
  display: block;
  height: auto;
  width: 75px;
  &,
  .gm-style & {
    max-width: 50%;
  }
`;

const ImageFallback = React.forwardRef<HTMLInputElement, ImageFallbackProps>(({ alt, ...rest }, ref) => (
  <ImageFallbackWrapper ref={ref} {...rest}>
    <ImageFallbackInner src={fallbackUrl} alt={alt} />
  </ImageFallbackWrapper>
));

ImageFallback.displayName = 'ImageFallback';

export default ImageFallback;
